import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Image from "gatsby-image"

const Bio = () => {
  const data = useStaticQuery(graphql`
    query BioQuery {
      avatar: file(absolutePath: { regex: "/profile-pic.jpg/" }) {
        childImageSharp {
          fixed(width: 50, height: 50, quality: 95) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      site {
        siteMetadata {
          author {
            name
            summary
          }
          social {
            twitter
          }
        }
      }
    }
  `)

  // Set these values by editing "siteMetadata" in gatsby-config.js
  const author = data.site.siteMetadata?.author
  const avatar = data?.avatar?.childImageSharp?.fixed

  return (
    <div className="bg-red-200 p-6 m-6 rounded-xl shadow-md flex flex-col items-center md:px-6 lg:mx-auto lg:max-w-4xl md:flex-row">
      {avatar && (
        <Image
          fixed={avatar}
          alt={author?.name || ``}
          className="flex-shrink-0 shadow-inner rounded-3xl mr-4"
        />
      )}
      {author?.name && (
        <p>
          Hey! You know me. Well, maybe not. Either way- I'm{" "}
          <span className="ugly-font font-bold">{author.name}</span>! {author?.summary || null}
        </p>
      )}
    </div>
  )
}

export default Bio
